import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import logoIcon from '../../assets/svg/cf-logo.svg';

export class DefaultErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    navigateHome = () => {
        if (typeof window !== 'undefined') window?.location?.replace?.(window?.location?.origin);
    }

    render() {
        let lang = 'se';
        if (typeof window !== 'undefined' && window?.origin?.indexOf('curoflow.se') === -1) {
            lang = 'en';
        }

        return (
            <div className='eb-container'>
                <div>
                    <div className='eb-logo'>
                        <Isvg src={logoIcon} />
                    </div>
                    <div className='eb-text'>
                        <h1>{lang === 'se' ? 'Ett tekniskt fel har uppstått!' : 'A technical error has occurred!'}</h1>
                        <p className='eb-message'>{lang === 'se' ? 'På grund av ett oväntat fel har applikationen stoppats. För att hjälpa oss åtgärda felet skickas automatiskt en rapport med information om felet. Du kan gå tillbaka till din sida genom att klicka på knappen nedan eller ladda om sidan.' : 'Due to an unexpected error, the application has stopped. To help us fix the error, a report is automatically sent with information about the error. You can go back to your page by clicking the button below or reload the page.'}</p>
                        <div className='eb-report'>
                            {this.props.reportSent === true
                                ? <p className='eb-p-green'>{lang === 'se' ? 'Rapport skickad!' : 'Report sent!'}</p>
                                : this.props.reportSent === false
                                    ? <p className='eb-p-red'>{lang === 'se' ? 'Fel när rapporten skickades!' : 'Error sending the report!'}</p>
                                    : <p className='eb-p-orange'>{lang === 'se' ? 'Skickar rapport' : 'Sending report'} ...</p>}
                        </div>
                        <div className='eb-buttons-container'>
                            <button onClick={this.navigateHome}>{lang === 'se' ? 'Gå tillbaka' : 'Go back'}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DefaultErrorPage
